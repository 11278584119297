import { LocalizationManager } from './localization-manager';

LocalizationManager.newProductComponent_pageHeader = function() {
	return LocalizationManager.localizedString("newProductComponent_pageHeader");
}

LocalizationManager.newProductComponent_pageInstruction = function() {
	return LocalizationManager.localizedString("newProductComponent_pageInstruction");
}

LocalizationManager.newProductComponent_productTitleTextFieldTitle = function() {
	return LocalizationManager.localizedString("newProductComponent_productTitleTextFieldTitle");
}

LocalizationManager.newProductComponent_submitButtonTitle = function() {
	return LocalizationManager.localizedString("newProductComponent_submitButtonTitle");
}


LocalizationManager.productCustomerRole_ownerTitle = function() {
	return LocalizationManager.localizedString("productCustomerRole_ownerTitle");
}

LocalizationManager.productCustomerRole_agentTitle = function() {
	return LocalizationManager.localizedString("productCustomerRole_agentTitle");
}


LocalizationManager.searchProductComponent_titleRowTitle = function() {
	return LocalizationManager.localizedString("searchProductComponent_titleRowTitle");
}

LocalizationManager.searchProductComponent_roleRowTitle = function() {
	return LocalizationManager.localizedString("searchProductComponent_roleRowTitle");
}

LocalizationManager.searchProductComponent_noDataMessage = function() {
	return LocalizationManager.localizedString("searchProductComponent_noDataMessage");
}


LocalizationManager.searchProductInvitationComponent_titleRowTitle = function() {
	return LocalizationManager.localizedString("searchProductInvitationComponent_titleRowTitle");
}

LocalizationManager.searchProductInvitationComponent_roleRowTitle = function() {
	return LocalizationManager.localizedString("searchProductInvitationComponent_roleRowTitle");
}

LocalizationManager.newProductComponent_acceptButtonTitle = function() {
	return LocalizationManager.localizedString("newProductComponent_acceptButtonTitle");
}

LocalizationManager.searchProductInvitationComponent_noDataMessage = function() {
	return LocalizationManager.localizedString("searchProductInvitationComponent_noDataMessage");
}

LocalizationManager.searchProductInvitationComponent_acceptInvitationConfirmationMessage = function() {
	return LocalizationManager.localizedString("searchProductInvitationComponent_acceptInvitationConfirmationMessage");
}


LocalizationManager.productDetailsComponent_productDetailsSectionTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_productDetailsSectionTitle");
}

LocalizationManager.productDetailsComponent_productTitleInputTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_productTitleInputTitle");
}

LocalizationManager.productDetailsComponent_productTitleInputPlaceholder = function() {
	return LocalizationManager.localizedString("productDetailsComponent_productTitleInputPlaceholder");
}

LocalizationManager.productDetailsComponent_productDetailsSubmitInputTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_productDetailsSubmitInputTitle");
}

LocalizationManager.productDetailsComponent_memberListSectionTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_memberListSectionTitle");
}

LocalizationManager.productDetailsComponent_memberListNameColumnTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_memberListNameColumnTitle");
}

LocalizationManager.productDetailsComponent_memberListRoleColumnTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_memberListRoleColumnTitle");
}

LocalizationManager.productDetailsComponent_memberListRemoveButtonTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_memberListRemoveButtonTitle");
}

LocalizationManager.productDetailsComponent_invitationListSectionTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_invitationListSectionTitle");
}

LocalizationManager.productDetailsComponent_invitationListEmailColumnTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_invitationListEmailColumnTitle");
}

LocalizationManager.productDetailsComponent_invitationListRoleColumnTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_invitationListRoleColumnTitle");
}

LocalizationManager.productDetailsComponent_invitationListEmailInputTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_invitationListEmailInputTitle");
}

LocalizationManager.productDetailsComponent_invitationListEmailInputPlaceholder = function() {
	return LocalizationManager.localizedString("productDetailsComponent_invitationListEmailInputPlaceholder");
}

LocalizationManager.productDetailsComponent_invitationListRoleInputDefaultValue = function() {
	return LocalizationManager.localizedString("productDetailsComponent_invitationListRoleInputDefaultValue");
}

LocalizationManager.productDetailsComponent_invitationListInviteButtonTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_invitationListInviteButtonTitle");
}

LocalizationManager.productDetailsComponent_invitationListRemoveButtonTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_invitationListRemoveButtonTitle");
}

LocalizationManager.productDetailsComponent_apiKeySectionTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_apiKeySectionTitle");
}

LocalizationManager.productDetailsComponent_apiKeyGeneratedOn = function() {
	return LocalizationManager.localizedString("productDetailsComponent_apiKeyGeneratedOn");
}

LocalizationManager.productDetailsComponent_apiKeyRegenerateButtonTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_apiKeyRegenerateButtonTitle");
}

LocalizationManager.productDetailsComponent_apiKeyRecoveryMessage = function() {
	return LocalizationManager.localizedString("productDetailsComponent_apiKeyRecoveryMessage");
}

LocalizationManager.productDetailsComponent_iosAppSectionTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_iosAppSectionTitle");
}

LocalizationManager.productDetailsComponent_iosAppBundleIdentifierInputTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_iosAppBundleIdentifierInputTitle");
}

LocalizationManager.productDetailsComponent_iosAppBundleIdentifierInputPlaceholder = function() {
	return LocalizationManager.localizedString("productDetailsComponent_iosAppBundleIdentifierInputPlaceholder");
}

LocalizationManager.productDetailsComponent_iosAppMinimumSupportedVersionInputTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_iosAppMinimumSupportedVersionInputTitle");
}

LocalizationManager.productDetailsComponent_iosAppMinimumSupportedVersionInputPlaceholder = function() {
	return LocalizationManager.localizedString("productDetailsComponent_iosAppMinimumSupportedVersionInputPlaceholder");
}

LocalizationManager.productDetailsComponent_iosAppLatestVersionInputTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_iosAppLatestVersionInputTitle");
}

LocalizationManager.productDetailsComponent_iosAppLatestVersionInputPlaceholder = function() {
	return LocalizationManager.localizedString("productDetailsComponent_iosAppLatestVersionInputPlaceholder");
}

LocalizationManager.productDetailsComponent_iosAppDownloadUrlInputTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_iosAppDownloadUrlInputTitle");
}

LocalizationManager.productDetailsComponent_iosAppDownloadUrlInputPlaceholder = function() {
	return LocalizationManager.localizedString("productDetailsComponent_iosAppDownloadUrlInputPlaceholder");
}

LocalizationManager.productDetailsComponent_iosAppSubmitButtonTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_iosAppSubmitButtonTitle");
}

LocalizationManager.productDetailsComponent_androidAppSectionTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_androidAppSectionTitle");
}

LocalizationManager.productDetailsComponent_androidAppBundleIdentifierInputTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_androidAppBundleIdentifierInputTitle");
}

LocalizationManager.productDetailsComponent_androidAppBundleIdentifierInputPlaceholder = function() {
	return LocalizationManager.localizedString("productDetailsComponent_androidAppBundleIdentifierInputPlaceholder");
}

LocalizationManager.productDetailsComponent_androidAppMinimumSupportedVersionInputTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_androidAppMinimumSupportedVersionInputTitle");
}

LocalizationManager.productDetailsComponent_androidAppMinimumSupportedVersionInputPlaceholder = function() {
	return LocalizationManager.localizedString("productDetailsComponent_androidAppMinimumSupportedVersionInputPlaceholder");
}

LocalizationManager.productDetailsComponent_androidAppLatestVersionInputTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_androidAppLatestVersionInputTitle");
}

LocalizationManager.productDetailsComponent_androidAppLatestVersionInputPlaceholder = function() {
	return LocalizationManager.localizedString("productDetailsComponent_androidAppLatestVersionInputPlaceholder");
}

LocalizationManager.productDetailsComponent_androidAppDownloadUrlInputTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_androidAppDownloadUrlInputTitle");
}

LocalizationManager.productDetailsComponent_androidAppDownloadUrlInputPlaceholder = function() {
	return LocalizationManager.localizedString("productDetailsComponent_androidAppDownloadUrlInputPlaceholder");
}

LocalizationManager.productDetailsComponent_androidAppSubmitButtonTitle = function() {
	return LocalizationManager.localizedString("productDetailsComponent_androidAppSubmitButtonTitle");
}
