
class IosAppSpecification {
	bundleIdentifier = null;
	minimumSupportedVersion = null;
	latestVersion = null;
	downloadUrl = null;
}


export { IosAppSpecification };
