import React from 'react';
import ReactDOM from "react-dom/client";

import FoundationComponent from '../modules/foundation/foundation-component';
import { ConfigurationManager } from '../collaborators/configuration-manager';
import { DataTransferManager } from '../data-manager/data-transfer-manager';

import '../modules/app-ui-kit/app-ui-kit.css';


class RoutingManager {
	static shared = new RoutingManager();


	perform() {
		this._setupAllData();
		this._setupRootView();
	}

	_setupAllData() {
		DataTransferManager.shared.environmentType = ConfigurationManager.dataTransferManagerEnvironmentType;
	}

	_setupRootView() {
		const aRootDom = ReactDOM.createRoot(document.getElementById("root"));
		aRootDom.render(
			<FoundationComponent />
		);
	}


	gotoHome() {
		window.location = URL.home();
	}

	gotoAppUpdatePopupYoutubeVideo() {
		window.open(ConfigurationManager.appUpdatePopupYoutubeVideoWebUrl, '_blank');
	}

	gotoSignUpLoginCustomer() {
		window.location = URL.signUpLoginCustomer();
	}

	gotoResetPassword() {
		window.location = URL.resetPassword();
	}

	gotoCustomerProfileDetails() {
		window.location = URL.customerProfileDetails();
	}

	gotoNewProduct() {
		window.location = URL.newProduct();
	}

	gotoSearchProduct() {
		window.location = URL.searchProduct();
	}

	gotoProductDetails(pProductUuid) {
		window.location = URL.productDetails(pProductUuid);
	}

	gotoSearchProductInvitation() {
		window.location = URL.searchProductInvitation();
	}

	gotoSearchPlan() {
		window.location = URL.searchPlan();
	}

	gotoSubscriptionDetails() {
		window.location = URL.subscriptionDetails();
	}

	gotoSearchKnowledgebase() {
		window.location = URL.searchKnowledgebase();
	}

	gotoSupportDetails() {
		window.location = URL.supportDetails();
	}

	get currentPath() {
		var aReturnVal = null;
		aReturnVal = window.location.pathname;
		if (aReturnVal == null || aReturnVal === "") {
			aReturnVal = "/"
		} else if (aReturnVal.slice(-1) !== "/") {
			aReturnVal += "/";
		}
		return aReturnVal;
	}

	get doesPageExist() {
		var aReturnVal = false;
		var aPath = RoutingManager.shared.currentPath;
		var aParameter = aPath.split("/").filter(Boolean).pop();
		if (
			aPath === URL.home()
			|| aPath === URL.signUpLoginCustomer()
			|| aPath === URL.resetPassword()
			|| aPath === URL.customerProfileDetails()
			|| aPath === URL.newProduct()
			|| aPath === URL.searchProduct()
			|| aPath === URL.productDetails(aParameter)
			|| aPath === URL.searchProductInvitation()
			|| aPath === URL.searchPlan()
			|| aPath === URL.subscriptionDetails()
			|| aPath === URL.searchKnowledgebase()
			|| aPath === URL.supportDetails()
		) {
			aReturnVal = true;
		}
		return aReturnVal;
	}

	get isHomeScreenDisplayed() {
		var aReturnVal = false;
		if (RoutingManager.shared.currentPath === URL.home()) {
			aReturnVal = true;
		}
		return aReturnVal;
	}

	get isSignUpLoginScreenDisplayed() {
		var aReturnVal = false;
		if (RoutingManager.shared.currentPath === URL.signUpLoginCustomer()) {
			aReturnVal = true;
		}
		return aReturnVal;
	}

	get isResetPasswordScreenDisplayed() {
		var aReturnVal = false;
		if (RoutingManager.shared.currentPath === URL.resetPassword()) {
			aReturnVal = true;
		}
		return aReturnVal;
	}

	get isSearchPlanScreenDisplayed() {
		var aReturnVal = false;
		if (RoutingManager.shared.currentPath === URL.searchPlan()) {
			aReturnVal = true;
		}
		return aReturnVal;
	}

	get isSearchKnowledgebaseScreenDisplayed() {
		var aReturnVal = false;
		if (RoutingManager.shared.currentPath === URL.searchKnowledgebase()) {
			aReturnVal = true;
		}
		return aReturnVal;
	}

	get isSupportDetailsScreenDisplayed() {
		var aReturnVal = false;
		if (RoutingManager.shared.currentPath === URL.supportDetails()) {
			aReturnVal = true;
		}
		return aReturnVal;
	}
}


/** @pragmamark - URLs **/

class URL {

	static baseUrl() {
		var aReturnVal = ""
		if (typeof ConfigurationManager.appRouterBaseName === "string" || ConfigurationManager.appRouterBaseName instanceof String) {
			aReturnVal = ConfigurationManager.appRouterBaseName;
			if (aReturnVal.slice(-1) == "/") {
				aReturnVal = aReturnVal.slice(0, -1);
			}
		}
		return aReturnVal;
	}

	static homePath() {
		return "/";
	}
	static home() {
		return this.baseUrl() +  this.homePath();
	}

	static signUpLoginCustomerPath() {
		return "/signup-login/";
	}
	static signUpLoginCustomer() {
		return this.baseUrl() +  this.signUpLoginCustomerPath();
	}

	static resetPasswordPath() {
		return "/reset-password/";
	}
	static resetPassword() {
		return this.baseUrl() +  this.resetPasswordPath();
	}

	static customerProfileDetailsPath() {
		return "/profile/";
	}
	static customerProfileDetails() {
		return this.baseUrl() + this.customerProfileDetailsPath();
	}

	static newProductPath() {
		return "/products/new/";
	}
	static newProduct() {
		return this.baseUrl() +  this.newProductPath();
	}

	static searchProductPath() {
		return "/products/";
	}
	static searchProduct() {
		return this.baseUrl() + this.searchProductPath();
	}

	static productDetailsPath(pProductUuid) {
		var aReturnVal = null;
		if (pProductUuid == null) {
			aReturnVal = "/products/:uuid/";
		} else {
			aReturnVal = "/products/" + pProductUuid + "/";
		}
		return aReturnVal;
	}
	static productDetails(pProductUuid) {
		return this.baseUrl() + this.productDetailsPath(pProductUuid);
	}

	static searchProductInvitationPath() {
		return "/product-invitations/";
	}
	static searchProductInvitation() {
		return this.baseUrl() + this.searchProductInvitationPath();
	}

	static searchPlanPath() {
		return "/pricing/";
	}
	static searchPlan() {
		return this.baseUrl() + this.searchPlanPath();
	}

	static subscriptionDetailsPath() {
		return "/subscriptions/";
	}
	static subscriptionDetails() {
		return this.baseUrl() + this.subscriptionDetailsPath();
	}

	static searchKnowledgebasePath() {
		return "/knowledgebase/";
	}
	static searchKnowledgebase() {
		return this.baseUrl() + this.searchKnowledgebasePath();
	}

	static supportDetailsPath() {
		return "/support/";
	}
	static supportDetails() {
		return this.baseUrl() + this.supportDetailsPath();
	}

}


export { RoutingManager, URL }
