import { DataContractManagerHttp } from './data-contract-manager-http';
import { DataTransferManagerResult, DataTransferManagerResultSignet } from './data-transfer-manager';


class DataTransferManagerMethodStub {

	methodStubCallbackDelayInMilliseconds = 1500;

	registerCustomerResponse() {
		var aReturnVal = `
		{
			"appVersion" : "0.9.0.0"
			, "payload" : {
				"signet" : "success"
				, "localizedDescription" : "You are registered successfully."
				, "outcome" : {
					"uuid" : ""
					, "displayName" : "Martin Riggs"
				}
			}
		}
		`
		;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}

	loginCustomerResponse() {
		var aReturnVal = `
		{
			"appVersion" : "0.9.0.0"
			, "payload" : {
				"signet" : "success"
				, "localizedDescription" : "You are logged in successfully."
				, "outcome" : {
					"token" : "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzZXNzaW9uVXVpZCI6IjRiMTAxMzJmYmZhNTQ1NDk5Y2U3ODIyMDI2MDYzNWRhIiwidXNlclR5cGUiOiJjdXN0b21lciIsImV4cCI6MTY2Mjc0NzQzMH0.BgNd9EvEIoY714r6nqdYtZF4f3IC8SsNobjEIDFGb_c"
				}
			}
		}
		`
		;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}

	logoutCustomerResponse() {
		var aReturnVal = `
		{
			"appVersion" : "0.9.0.0"
			, "payload" : {
				"signet" : "success"
				, "localizedDescription" : "You are logged out successfully."
				, "outcome" : true
			}
		}
		`
		;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}

	newResetCustomerPasswordCodeResponse() {
		var aReturnVal = `
		{
			"appVersion" : "0.9.0.0"
			, "payload" : {
				"signet" : "success"
				, "localizedDescription" : "Instructions to reset your password are sent you over an email successfully."
				, "outcome" : true
			}
		}
		`
		;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}

	resetCustomerPasswordResponse() {
		var aReturnVal = `
		{
			"appVersion" : "0.9.0.0"
			, "payload" : {
				"signet" : "success"
				, "localizedDescription" : "Password reset successfully."
				, "outcome" : true
			}
		}
		`
		;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}

	profileDetailsResponse() {
		var aReturnVal = `
		{
			"appIdentifier": "com.aurvan.sahayak.api.php",
			"appVersion": "1.0.0.0",
			"payload": {
				"signet": "success",
				"localizedDescription": "Profile details fetched successfully.",
				"outcome": {
					"uuid": "2d9e73eece8944149ff461911d567d06",
					"addedOnUsdfDate": "2022-08-27T20:23:41.000000Z",
					"displayName": "Martin Riggs",
					"identities": [
						{
							"uuid": "73fd54f9b34740f19d54463885d7f035",
							"addedOnUsdfDate": "2022-08-27T20:23:41.000000Z",
							"customerUuid": null,
							"typeSignet": "email",
							"value": "martin@example.com"
						}
					]
				}
			}
		}
		`
		;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}

	updateCustomerProfileResponse() {
		var aReturnVal = `
		{
			"appIdentifier": "com.aurvan.sahayak.api.php",
			"appVersion": "1.0.0.0",
			"payload": {
				"signet": "success",
				"localizedDescription": "Profile updated successfully.",
				"outcome": {
					"uuid": "2d9e73eece8944149ff461911d567d06",
					"addedOnUsdfDate": null,
					"userTypeSignet": "customer",
					"displayName": "Uday Kumar Shetty",
					"identities": null,
					"subscriptions": null,
					"combinedActiveSubscriptionBenefit": null
				}
			}
		}
		`
		;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}

	updateCustomerPasswordResponse() {
		var aReturnVal = `
		{
			"appIdentifier": "com.aurvan.sahayak.api.php",
			"appVersion": "1.0.0.0",
			"payload": {
				"signet": "success",
				"localizedDescription": "Password updated successfully.",
				"outcome": {
					"uuid": "2d9e73eece8944149ff461911d567d06",
					"addedOnUsdfDate": null,
					"userTypeSignet": "customer",
					"displayName": null,
					"identities": null,
					"subscriptions": null,
					"combinedActiveSubscriptionBenefit": null
				}
			}
		}
		`
		;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}

	newCustomerIdentityResponse() {
		var aReturnVal = `
		{
			"appIdentifier": "com.aurvan.sahayak.api.php",
			"appVersion": "1.0.0.0",
			"payload": {
				"signet": "success",
				"localizedDescription": "Identity created successfully.",
				"outcome": {
					"uuid": "e7fd5458873444af918812f660e21d2a",
					"addedOnUsdfDate": null,
					"typeSignet": "email",
					"value": "uday.shetty@example.com",
					"verifiedOnUsdfDate": null
				}
			}
		}
		`
		;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}

	verifyCustomerIdentityResponse() {
		var aReturnVal = `
		{
			"appIdentifier": "com.aurvan.sahayak.api.php",
			"appVersion": "1.0.0.0",
			"payload": {
				"signet": "success",
				"localizedDescription": "Identity verified successfully.",
				"outcome": {
					"uuid": "e7fd5458873444af918812f660e21d2a",
					"addedOnUsdfDate": null,
					"typeSignet": "email",
					"value": "uday.shetty@example.com",
					"verifiedOnUsdfDate": null
				}
			}
		}
		`
		;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}

	newVerifyCustomerIdentityCodeResponse() {
		var aReturnVal = `
		{
			"appIdentifier": "com.aurvan.sahayak.api.php",
			"appVersion": "1.0.0.0",
			"payload": {
				"signet": "success",
				"localizedDescription": "Identity verification code sent successfully.",
				"outcome": {
					"uuid": "e7fd5458873444af918812f660e21d2a",
					"addedOnUsdfDate": null,
					"typeSignet": "email",
					"value": "uday.shetty@example.com",
					"verifiedOnUsdfDate": null
				}
			}
		}
		`
		;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}

	deleteCustomerIdentityResponse() {
		var aReturnVal = `
		{
			"appIdentifier": "com.aurvan.sahayak.api.php",
			"appVersion": "1.0.0.0",
			"payload": {
				"signet": "success",
				"localizedDescription": "Identity deleted successfully.",
				"outcome": {
					"uuid": "e7fd5458873444af918812f660e21d2a",
					"addedOnUsdfDate": null,
					"typeSignet": "email",
					"value": "uday.shetty@example.com",
					"verifiedOnUsdfDate": null
				}
			}
		}
		`
		;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}

	newProductResponse() {
		var aReturnVal = `
		{
			"appVersion" : "0.9.0.0"
			, "payload" : {
				"signet" : "success"
				, "localizedDescription" : "Product created successfully."
				, "outcome" : {
					"uuid" : "66d2967937ad43a695a136f90120e7e0"
					, "title" : "Ubuntu"
				}
			}
		}
		`
		;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}

	productDetailsResponse() {
		var aReturnVal = `
		{
			"appVersion" : "0.9.0.0"
			, "payload" : {
				"signet" : "success"
				, "localizedDescription" : "Product details fetched successfully."
				, "outcome" : {
					"uuid" : "66d2967937ad43a695a136f90120e7e0"
					, "title" : "Ubuntu"
				}
			}
		}
		`
		;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}

	updateProductResponse() {
		var aReturnVal = `
		{
			"appVersion" : "0.9.0.0"
			, "payload" : {
				"signet" : "success"
				, "localizedDescription" : "Product updated successfully."
				, "outcome" : {
					"uuid" : "66d2967937ad43a695a136f90120e7e0"
					, "title" : "Ubuntu"
				}
			}
		}
		`
		;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}

	updateIosAppSpecificationResponse() {
		var aReturnVal = `
		{
			"appIdentifier": "com.aurvan.sahayak.api.php",
			"appVersion": "1.0.0.0",
			"payload": {
				"signet": "success",
				"localizedDescription": "iOS app specification updated successfully.",
				"outcome": {
					"bundleIdentifier": "com.example.myapp.ios",
					"minimumSupportedVersion": "1.2.3",
					"latestVersion": "2.2.3",
					"downloadUrl": "http://example.com/downloads/ios-app/",
				}
			}
		}
		`
		;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}

	searchProductCustomerMapResponse() {
		var aReturnVal = `
		{
			"appVersion" : "0.9.0.0"
			, "payload" : {
				"signet" : "success"
				, "localizedDescription" : "Product searched successfully."
				, "outcome" : [
					{
						"uuid" : "e7d5f4e48f4c40a496da03b371269168"
						, "addedOnUsdfDate" : "2020-10-01T20:09:32.000000Z"
						, "product" : {
							"uuid" : "66d2967937ad43a695a136f90120e7e0"
							, "title" : "Ubuntu"
						}
						, "role" : "owner"
					}
					, {
						"uuid" : "02a6af6734a843a99ea445a6a433a398"
						, "addedOnUsdfDate" : "2020-10-01T20:09:32.000000Z"
						, "product" : {
							"uuid" : "5d330d26ae884c9fb44eadc19111f33d"
							, "title" : "Cent OS"
						}
						, "role" : "agent"
					}
					, {
						"uuid" : "9908730dc0774f50a8d2ab5dce0c07df"
						, "addedOnUsdfDate" : "2020-10-01T20:09:32.000000Z"
						, "product" : {
							"uuid" : "2376ff3e050c424f80b9ef6fb414f1e2"
							, "title" : "Kali"
						}
						, "role" : "owner"
					}
					, {
						"uuid" : "53fd32c0e5fc4dde85574b717e570b58"
						, "addedOnUsdfDate" : "2020-10-01T20:09:32.000000Z"
						, "product" : {
							"uuid" : "1760ae675cf74869896f84ebaba1a3b9"
							, "title" : "Red Hat"
						}
						, "role" : "agent"
					}
				]
			}
		}
		`;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}


	deleteProductCustomerMapResponse() {
		var aReturnVal = `
		{
			"appVersion" : "0.9.0.0"
			, "payload" : {
				"signet" : "success"
				, "localizedDescription" : "Product customer map deleted successfully."
				, "outcome" : true
			}
		}
		`;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}


	newProductInvitationResponse() {
		var aReturnVal = `
		{
			"appVersion" : "0.9.0.0"
			, "payload" : {
				"signet" : "success"
				, "localizedDescription" : "Product invitation created successfully."
				, "outcome" : true
			}
		}
		`;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}


	searchProductInvitationResponse() {
		var aReturnVal = `
		{
			"appVersion" : "0.9.0.0"
			, "payload" : {
				"signet" : "success"
				, "localizedDescription" : "Product customer invitation searched successfully."
				, "outcome" : [
					{
						"uuid": "379f4c7875a94b24bcd31e6479d42a1a",
						"addedOnUsdfDate": "2022-09-12T12:04:52.000000Z",
						"product": {
							"uuid" : "2376ff3e050c424f80b9ef6fb414f1e2"
							, "title" : "Kali"
						},
						"emailAddress": "neo@example.com",
						"role": "agent"
					},
					{
						"uuid": "55d6f8d25d9448078b55d9294c698df2",
						"addedOnUsdfDate": "2022-09-11T05:27:37.000000Z",
						"product": {
							"uuid" : "1760ae675cf74869896f84ebaba1a3b9"
							, "title" : "Red Hat"
						},
						"emailAddress": "neo@example.com",
						"role": "agent"
					}
				]
			}
		}
		`;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}


	deleteProductInvitationResponse() {
		var aReturnVal = `
		{
			"appVersion" : "0.9.0.0"
			, "payload" : {
				"signet" : "success"
				, "localizedDescription" : "Product invitation deleted successfully."
				, "outcome" : {
					"uuid": "55d6f8d25d9448078b55d9294c698df2",
					"addedOnUsdfDate": "2022-09-11T05:27:37.000000Z",
					"product": {
						"uuid" : "1760ae675cf74869896f84ebaba1a3b9"
						, "title" : "Red Hat"
					},
					"emailAddress": "neo@example.com",
					"role": "agent"
				}
			}
		}
		`;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}


	acceptProductInvitationResponse() {
		var aReturnVal = `
		{
			"appVersion" : "0.9.0.0"
			, "payload" : {
				"signet" : "success"
				, "localizedDescription" : "Product invitation accepted successfully."
				, "outcome" : true
			}
		}
		`;

		aReturnVal = aReturnVal.replace(/(\r\n|\n|\r)/gm, "");

		return aReturnVal
	}


	registerCustomer(pCallback, pCustomer) {
		var aResponseData = this.registerCustomerResponse();
		var aResult = DataContractManagerHttp.registerCustomerResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	loginCustomer(pCallback, pCustomer) {
		var aResponseData = this.loginCustomerResponse();
		var aResult = DataContractManagerHttp.loginCustomerResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	logoutCustomer(pCallback, pCustomer) {
		var aResponseData = this.logoutCustomerResponse();
		var aResult = DataContractManagerHttp.logoutCustomerResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	newResetCustomerPasswordCode(pCallback, pCustomer) {
		var aResponseData = this.newResetCustomerPasswordCodeResponse();
		var aResult = DataContractManagerHttp.newResetCustomerPasswordCodeResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	resetCustomerPassword(pCallback, pCustomer) {
		var aResponseData = this.resetCustomerPasswordResponse();
		var aResult = DataContractManagerHttp.resetCustomerPasswordResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	profileDetails(pCallback, pCustomer) {
		var aResponseData = this.profileDetailsResponse();
		var aResult = DataContractManagerHttp.profileDetailsResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	updateCustomerProfile(pCallback, pCustomer) {
		var aResponseData = this.updateCustomerProfileResponse();
		var aResult = DataContractManagerHttp.updateCustomerProfileResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	updateCustomerPassword(pCallback, pCustomer) {
		var aResponseData = this.updateCustomerPasswordResponse();
		var aResult = DataContractManagerHttp.updateCustomerPasswordResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	newCustomerIdentity(pCallback, pCustomerIdentity) {
		var aResponseData = this.newCustomerIdentityResponse();
		var aResult = DataContractManagerHttp.newCustomerIdentityResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	verifyCustomerIdentity(pCallback, pCustomerIdentity) {
		var aResponseData = this.verifyCustomerIdentityResponse();
		var aResult = DataContractManagerHttp.verifyCustomerIdentityResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	newVerifyCustomerIdentityCode(pCallback, pCustomerIdentity) {
		var aResponseData = this.newVerifyCustomerIdentityCodeResponse();
		var aResult = DataContractManagerHttp.newVerifyCustomerIdentityCodeResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	deleteCustomerIdentity(pCallback, pCustomerIdentity) {
		var aResponseData = this.deleteCustomerIdentityResponse();
		var aResult = DataContractManagerHttp.deleteCustomerIdentityResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	newProduct(pCallback, pProduct) {
		var aResponseData = this.newProductResponse();
		var aResult = DataContractManagerHttp.newProductResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	productDetails(pCallback, pProductUuid) {
		var aResponseData = this.productDetailsResponse();
		var aResult = DataContractManagerHttp.productDetailsResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	updateProduct(pCallback, pProduct) {
		var aResponseData = this.updateProductResponse();
		var aResult = DataContractManagerHttp.updateProductResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	updateIosAppSpecification(pCallback, pIosAppSpecification, pProductUuid, pRequestParameterArray) {
		var aResponseData = this.updateIosAppSpecificationResponse();
		var aResult = DataContractManagerHttp.updateIosAppSpecificationResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	updateAndroidAppSpecification(pCallback, pAndroidAppSpecification, pProductUuid, pRequestParameterArray) {
		var aResponseData = this.updateAndroidAppSpecificationResponse();
		var aResult = DataContractManagerHttp.updateAndroidAppSpecificationResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	searchProductCustomerMap(pCallback, pProductUuid, pAssigneeUuid) {
		var aResponseData = this.searchProductCustomerMapResponse();
		var aResult = DataContractManagerHttp.searchProductCustomerMapResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	deleteProductCustomerMap(pCallback, pProductCustomerMapUuid) {
		var aResponseData = this.deleteProductCustomerMapResponse();
		var aResult = DataContractManagerHttp.deleteProductCustomerMapResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	newProductInvitation(pCallback, pProductInvitationUuid) {
		var aResponseData = this.newProductInvitationResponse();
		var aResult = DataContractManagerHttp.newProductInvitationResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	searchProductInvitation(pCallback, pProductUuid) {
		var aResponseData = this.searchProductInvitationResponse();
		var aResult = DataContractManagerHttp.searchProductInvitationResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	deleteProductInvitation(pCallback, pProductInvitationUuid) {
		var aResponseData = this.deleteProductInvitationResponse();
		var aResult = DataContractManagerHttp.deleteProductInvitationResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}


	acceptProductInvitation(pCallback, pProductInvitationUuid) {
		var aResponseData = this.acceptProductInvitationResponse();
		var aResult = DataContractManagerHttp.acceptProductInvitationResult(aResponseData);
		setTimeout(function() {
			pCallback(aResult);
		}, this.methodStubCallbackDelayInMilliseconds);
	}

}


export {DataTransferManagerMethodStub}
