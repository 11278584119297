import React from 'react';
import BaseComponent from '../app-ui-kit/base-component';
import { withParams } from '../app-ui-kit/higher-order-component';

import { Product } from "../../models/product"
import { IosAppSpecification } from '../../models/ios-app-specification';
import { AndroidAppSpecification } from '../../models/android-app-specification';
import { DataTransferManager, DataTransferManagerResultSignet } from "../../data-manager/data-transfer-manager"
import { ProgressIndicationManager } from '../../collaborators/progress-indication-manager';
import { PopupManager } from '../../collaborators/popup-manager';
import { AppButton, AppButtonType } from '../app-ui-kit/app-button';
import { AppTextField } from '../app-ui-kit/app-text-field';
import { AppFileField, AppFileFieldModel } from '../app-ui-kit/app-file-field';
import { AppTable, AppTableRow, AppTableCell } from '../app-ui-kit/app-table';
import { ProductInvitation } from '../../models/product-invitation';
import { RoutingManager, URL } from '../../collaborators/routing-manager';
import { ProductCustomerRole } from '../../models/product-customer-role';
import { AppSpacer } from '../app-ui-kit/app-ui-kit';

import "./product-details-component.css"
import { ATDateManager } from "../../third-party/com-aurvan-atkit-reactjs/src/at-date-manager";
import { CacheManager } from '../../collaborators/cache-manager';
import { RequestParameter } from '../../models/request-parameter';
import { LocalizationManager } from '../../localization-manager/localization-manager';
import { ATEncryptionManager } from "../../third-party/com-aurvan-atkit-reactjs/src/at-encryption-manager";
import { ATUtilityManager } from '../../third-party/com-aurvan-atkit-reactjs/src/at-utility-manager';


class ProductDetailsComponent extends BaseComponent {
	product = null;
	productCustomerMaps = null;
	isLoggedInUserOwner = false;


	constructor(props) {
		super(props);

		this.productTitleTextField = React.createRef();
		this.iosAppBundleIdentifierTextField = React.createRef();
		this.iosAppMinimumSupportedVersionTextField = React.createRef();
		this.iosAppLatestVersionTextField = React.createRef();
		this.iosAppDownloadUrlTextField = React.createRef();
		this.androidAppBundleIdentifierTextField = React.createRef();
		this.androidAppMinimumSupportedVersionTextField = React.createRef();
		this.androidAppLatestVersionTextField = React.createRef();
		this.androidAppDownloadUrlTextField = React.createRef();

		this.invitationEmailAddressTextField = React.createRef();
	}

	reloadAllData() {
		if (CacheManager.shared.loggedInCustomer != null) {
			var aThis = this;
			aThis.productDetails(function(pResultSignet) {
				if (pResultSignet === DataTransferManagerResultSignet.success) {
					aThis.searchProductCustomerMap(function() {
						if (pResultSignet === DataTransferManagerResultSignet.success) {
							if (aThis.isLoggedInCustomerOwnerOfCurrentProduct()) {
								aThis.searchProductInvitation();
							}
						}
					});
				}
			});
		}
	}

	reloadAllViews() {
		if (this.props.ceilingBarHeaderLabel != null && this.props.ceilingBarHeaderLabel.current != null
		&& this.product != null && this.product.title != null) {
			this.props.ceilingBarHeaderLabel.current.innerHTML = this.product.title;
		}

		this.reloadUpdateProductView()

		this.reloadIosAppSpecificationView()

		this.reloadAndroidAppSpecificationView()

		this.forceUpdate();
	}

	resetAllViews() {
		this.invitationEmailAddressTextField.current.value = null;
	}

	render() {
		var aReturnVal = this.loginRequiredView();
		if (CacheManager.shared.loggedInCustomer != null) {
			aReturnVal = this.productDetailsView();
		}
		return aReturnVal;
	}

}


// MARK:- Views

ProductDetailsComponent.prototype.productDetailsView = function() {
	return (
		<div className="foundation-storey vertical-scroll-view">
			{this.updateProductView()}
			{this.memberListView()}
			{this.invitationListView()}
			{this.apiKeySpecificationView()}
			{this.iosAppSpecificationView()}
			{this.androidAppSpecificationView()}
			<AppSpacer height="40px" />
		</div>
	);
}

ProductDetailsComponent.prototype.updateProductView = function() {
	var aReturnVal = null;

	var aRowArray = [];
	
	if (this.isLoggedInUserOwner) {
		var anUpdateProductFormRow = new AppTableRow(
			[
				AppTableCell.initWithView(
					<AppTextField
						title={LocalizationManager.productDetailsComponent_productTitleInputTitle() + " :"}
						placeholder={LocalizationManager.productDetailsComponent_productTitleInputPlaceholder()}
						ref={this.productTitleTextField}
						inputClassNames={["product-details-text-field"]}
					/>
				)
				, AppTableCell.initWithView(
					<AppButton
						title={LocalizationManager.productDetailsComponent_productDetailsSubmitInputTitle()}
						type={AppButtonType.arcRect}
						didSelectCallback={this.didSelectUpdateProduct.bind(this)}
						inputClassNames={["product-details-arc-button"]}
					/>
					, ["product-details-cta-cell"]
				)
			]
		);
		aRowArray.push(anUpdateProductFormRow);
	} else {
		var aProductTitle = ""
		if (this.product != null && this.product.title != null) {
			aProductTitle = this.product.title;
		}
		var aProductDetailsRow = new AppTableRow(
			[
				AppTableCell.initWithTitle(
					aProductTitle
				)
			]
		);
		aRowArray.push(aProductDetailsRow);
	}

	var aSection = [];
	aSection.push(<div className="product-details-section-title" key="product_details_title">{LocalizationManager.productDetailsComponent_productDetailsSectionTitle()}</div>);
	aSection.push(<AppSpacer height="8px" key="product_details_space" />);
	aSection.push(<AppTable data={aRowArray} key="product_details_list" />);

	aReturnVal = aSection;

	return aReturnVal;
}

ProductDetailsComponent.prototype.reloadUpdateProductView = function() {
	if (this.productTitleTextField != null && this.productTitleTextField.current != null
		&& this.product != null && this.product.title != null) {
		this.productTitleTextField.current.value = this.product.title;
	}
}

ProductDetailsComponent.prototype.memberListView = function() {
	var aReturnVal = null;

	var aRowArray = [];

	var aHeaderRow = new AppTableRow(
		[
			AppTableCell.initWithTitle(LocalizationManager.productDetailsComponent_memberListNameColumnTitle())
			, AppTableCell.initWithTitle(LocalizationManager.productDetailsComponent_memberListRoleColumnTitle(), ["product-details-role-cell"])
			, AppTableCell.initWithTitle("", ["product-details-cta-cell"])
		]
		, null
		, null
		, true
	);
	aRowArray.push(aHeaderRow);

	if (this.productCustomerMaps instanceof Array && this.productCustomerMaps.length > 0) {
		for (var anIndex = 0; anIndex < this.productCustomerMaps.length; anIndex ++) {
			var aProductCustomerMap = this.productCustomerMaps[anIndex];
			var aCellArray = [];
			aCellArray.push(AppTableCell.initWithTitle(aProductCustomerMap.customer.displayName));
			aCellArray.push(AppTableCell.initWithTitle(aProductCustomerMap.role.localizedTitle(), ["product-details-role-cell"]));
			if (aProductCustomerMap.role.equals(ProductCustomerRole.owner)) {
				aCellArray.push(AppTableCell.initWithTitle(" ", ["product-details-cta-cell"]));
			} else {
				if (this.isLoggedInUserOwner === true) {
					aCellArray.push(AppTableCell.initWithView(
						<AppButton
							title={LocalizationManager.productDetailsComponent_memberListRemoveButtonTitle()}
							type={AppButtonType.arcRect}
							didSelectCallback={this.didSelectDeleteProductCustomerMap.bind(this, aProductCustomerMap.uuid)}
							inputClassNames={["product-details-arc-button"]}
							isDestructive={true}
						/>
						, ["product-details-cta-cell"])
					);
				} else {
					aCellArray.push(AppTableCell.initWithTitle(" ", ["product-details-cta-cell"]));
				}
			}
			aRowArray.push(
				new AppTableRow(aCellArray)
			);
		}
	}

	var aSection = [];
	aSection.push(<AppSpacer height="40px" key="member_top_space" />);
	aSection.push(<div className="product-details-section-title" key="member_title">{LocalizationManager.productDetailsComponent_memberListSectionTitle()}</div>);
	aSection.push(<AppSpacer height="8px" key="member_line_space" />);
	aSection.push(<AppTable data={aRowArray} key="member_list" />);

	aReturnVal = aSection;

	return aReturnVal;
}


ProductDetailsComponent.prototype.invitationListView = function() {
	var aReturnVal = null;

	var aRowArray = [];

	var aHeaderRow = new AppTableRow(
		[
			AppTableCell.initWithTitle(LocalizationManager.productDetailsComponent_invitationListEmailColumnTitle())
			, AppTableCell.initWithTitle(LocalizationManager.productDetailsComponent_invitationListRoleColumnTitle(), ["product-details-role-cell"])
			, AppTableCell.initWithTitle("", ["product-details-cta-cell"])
		]
		, null
		, null
		, true
	);
	aRowArray.push(aHeaderRow);

	var anInvitationFormRow = new AppTableRow(
		[
			AppTableCell.initWithView(
				<AppTextField
					title={LocalizationManager.productDetailsComponent_invitationListEmailInputTitle() + " :"}
					placeholder={LocalizationManager.productDetailsComponent_invitationListEmailInputPlaceholder()}
					inputClassNames={["product-details-text-field"]}
					ref={this.invitationEmailAddressTextField}
				/>
			)
			, AppTableCell.initWithTitle(LocalizationManager.productDetailsComponent_invitationListRoleInputDefaultValue(), ["product-details-role-cell"])
			, AppTableCell.initWithView(
				<AppButton
					title={LocalizationManager.productDetailsComponent_invitationListInviteButtonTitle()}
					type={AppButtonType.arcRect}
					didSelectCallback={this.didSelectNewProductInvitation.bind(this)}
					inputClassNames={["product-details-arc-button"]}
				/>
				, ["product-details-cta-cell"]
			)
		]
	);
	aRowArray.push(anInvitationFormRow);

	if (this.productInvitations instanceof Array && this.productInvitations.length > 0) {
		for (var anIndex = 0; anIndex < this.productInvitations.length; anIndex ++) {
			var aProductInvitation = this.productInvitations[anIndex];
			var aCellArray = [];
			aCellArray.push(AppTableCell.initWithTitle(aProductInvitation.emailAddress));
			aCellArray.push(AppTableCell.initWithTitle(aProductInvitation.role.localizedTitle(), ["product-details-role-cell"]));
			aCellArray.push(AppTableCell.initWithView(
				<AppButton
					title={LocalizationManager.productDetailsComponent_invitationListRemoveButtonTitle()}
					type={AppButtonType.arcRect}
					didSelectCallback={this.didSelectDeleteProductInvitation.bind(this, aProductInvitation.uuid)}
					inputClassNames={["product-details-arc-button"]}
					isDestructive={true}
				/>
				, ["product-details-cta-cell"])
			);
			aRowArray.push(
				new AppTableRow(aCellArray)
			);
		}
	}

	var aSection = [];
	if (this.isLoggedInCustomerOwnerOfCurrentProduct()) {
		aSection.push(<AppSpacer height="40px" key="invitation_top_space" />);
		aSection.push(<div className="product-details-section-title" key="invitation_title">{LocalizationManager.productDetailsComponent_invitationListSectionTitle()}</div>);
		aSection.push(<AppSpacer height="8px" key="invitation_line_space" />);
		aSection.push(<AppTable data={aRowArray} key="invitation_list" />);
	}

	aReturnVal = aSection;

	return aReturnVal;
}


ProductDetailsComponent.prototype.apiKeySpecificationView = function() {
	var aReturnVal = null;

	var aRowArray = [];

	var aCellArray = [];
	if (this.product != null && this.product.apiKey != null && this.product.apiKey.addedOnUsdfDate != null) {
		var anApiKeyGeneratedOnDate = LocalizationManager.productDetailsComponent_apiKeyGeneratedOn() + " " + ATDateManager.stringFromUsdfDateWithFormat(this.product.apiKey.addedOnUsdfDate, "dd-mmm-yyyy 'at' hh:MM tt");
		aCellArray.push(
			AppTableCell.initWithTitle(
				anApiKeyGeneratedOnDate
				, ["api-key-date-cell"]
			)
		);
		aCellArray.push(
			AppTableCell.initWithView(
				<AppButton
					title={LocalizationManager.productDetailsComponent_apiKeyRegenerateButtonTitle()}
					type={AppButtonType.arcRect}
					didSelectCallback={this.didSelectNewProductApiKey.bind(this)}
					inputClassNames={["product-details-arc-button"]}
				/>
				, ["product-details-cta-cell"]
			)
		);
	} else {
		aCellArray.push(
			AppTableCell.initWithTitle(
				"API key is not generated yet."
				, ["api-key-date-cell"]
			)
		);
		aCellArray.push(
			AppTableCell.initWithTitle(
				" "
				, ["api-key-date-cell"]
			)
		);
		aCellArray.push(
			AppTableCell.initWithView(
				<AppButton
					title={"Generate"}
					type={AppButtonType.arcRect}
					didSelectCallback={this.didSelectNewProductApiKey.bind(this)}
					inputClassNames={["product-details-arc-button"]}
				/>
				, ["product-details-cta-cell"]
			)
		);
	}

	aRowArray.push(
		new AppTableRow(
			aCellArray
		)
	);

	aRowArray.push(
		new AppTableRow(
			[
				AppTableCell.initWithTitle(
					LocalizationManager.productDetailsComponent_apiKeyRecoveryMessage()
					, ["api-key-message-cell"]
				)
			]
			, ["api-key-message-row"]
		)
	);

	var aSection = [];
	if (this.isLoggedInCustomerOwnerOfCurrentProduct()) {
		aSection.push(<AppSpacer height="40px" key="api_key_top_space" />);
		aSection.push(<div className="product-details-section-title" key="api_key_title">{LocalizationManager.productDetailsComponent_apiKeySectionTitle()}</div>);
		aSection.push(<AppSpacer height="8px" key="api_key_line_space" />);
		aSection.push(<AppTable data={aRowArray} key="api_key_list" />);
	}

	aReturnVal = aSection;

	return aReturnVal;
}


ProductDetailsComponent.prototype.iosAppSpecificationView = function() {
	var aReturnVal = null;

	var aRowArray = [];

	aRowArray.push(
		new AppTableRow(
			[
				AppTableCell.initWithView(
					<AppTextField
						title={LocalizationManager.productDetailsComponent_iosAppBundleIdentifierInputTitle() + " :"}
						placeholder={LocalizationManager.productDetailsComponent_iosAppBundleIdentifierInputPlaceholder()}
						inputClassNames={["product-details-text-field"]}
						ref={this.iosAppBundleIdentifierTextField}
					/>
					, null
				)
				, AppTableCell.initWithTitle(
					""
					, null
				)
			]
			, ["ios-app-specification-row"]
		)
	);

	aRowArray.push(
		new AppTableRow(
			[
				AppTableCell.initWithView(
					<div className="app-separator-horizontal"></div>
					, null
				)
			]
			, ["ios-app-specification-row", "product-details-row-separator"]
		)
	);

	aRowArray.push(
		new AppTableRow(
			[
				AppTableCell.initWithView(
					<AppTextField
						title={LocalizationManager.productDetailsComponent_iosAppMinimumSupportedVersionInputTitle() + " :"}
						placeholder={LocalizationManager.productDetailsComponent_iosAppMinimumSupportedVersionInputPlaceholder()}
						inputClassNames={["product-details-text-field"]}
						ref={this.iosAppMinimumSupportedVersionTextField}
					/>
					, null
				)
				, AppTableCell.initWithView(
					<AppTextField
						title={LocalizationManager.productDetailsComponent_iosAppLatestVersionInputTitle() + " :"}
						placeholder={LocalizationManager.productDetailsComponent_iosAppLatestVersionInputPlaceholder()}
						inputClassNames={["product-details-text-field"]}
						ref={this.iosAppLatestVersionTextField}
					/>
					, null
				)
			]
			, ["ios-app-specification-row"]
		)
	);

	aRowArray.push(
		new AppTableRow(
			[
				AppTableCell.initWithView(
					<AppTextField
						title={LocalizationManager.productDetailsComponent_iosAppDownloadUrlInputTitle() + " :"}
						placeholder={LocalizationManager.productDetailsComponent_iosAppDownloadUrlInputPlaceholder()}
						inputClassNames={["product-details-text-field"]}
						ref={this.iosAppDownloadUrlTextField}
					/>
					, null
				)
				, AppTableCell.initWithTitle(
					""
					, null
				)
			]
			, ["ios-app-specification-row"]
		)
	);

	aRowArray.push(
		new AppTableRow(
			[
				AppTableCell.initWithView(
					<div className="app-separator-horizontal"></div>
					, null
				)
			]
			, ["ios-app-specification-row", "product-details-row-separator"]
		)
	);

	aRowArray.push(
		new AppTableRow(
			[
				AppTableCell.initWithView(
					<AppButton
						title={LocalizationManager.productDetailsComponent_iosAppSubmitButtonTitle()}
						type={AppButtonType.arcRect}
						didSelectCallback={this.didSelectUpdateIosAppSpecification.bind(this)}
						inputClassNames={["product-details-arc-button"]}
					/>
					, ["ios-app-specification-cell"]
				)
			]
			, ["ios-app-specification-row"]
		)
	);

	var aSection = [];
	if (this.isLoggedInCustomerOwnerOfCurrentProduct()) {
		aSection.push(<AppSpacer height="40px" key="ios_app_top_space" />);
		aSection.push(<div className="product-details-section-title" key="ios_app_title">{LocalizationManager.productDetailsComponent_iosAppSectionTitle()}</div>);
		aSection.push(<AppSpacer height="8px" key="ios_app_line_space" />);
		aSection.push(<AppTable data={aRowArray} key="ios_app_list" />);
	}

	aReturnVal = aSection;

	return aReturnVal;
}

ProductDetailsComponent.prototype.reloadIosAppSpecificationView = function() {
	if (this.product != null && this.product.iosAppSpecification != null && this.iosAppBundleIdentifierTextField.current != null) {
		this.iosAppBundleIdentifierTextField.current.value = this.product.iosAppSpecification.bundleIdentifier;
		this.iosAppMinimumSupportedVersionTextField.current.value = this.product.iosAppSpecification.minimumSupportedVersion;
		this.iosAppLatestVersionTextField.current.value = this.product.iosAppSpecification.latestVersion;
		this.iosAppDownloadUrlTextField.current.value = this.product.iosAppSpecification.downloadUrl;
	}
}

ProductDetailsComponent.prototype.androidAppSpecificationView = function() {
	var aReturnVal = null;

	var aRowArray = [];

	aRowArray.push(
		new AppTableRow(
			[
				AppTableCell.initWithView(
					<AppTextField
						title={LocalizationManager.productDetailsComponent_androidAppBundleIdentifierInputTitle() + " :"}
						placeholder={LocalizationManager.productDetailsComponent_androidAppBundleIdentifierInputPlaceholder()}
						inputClassNames={["product-details-text-field"]}
						ref={this.androidAppBundleIdentifierTextField}
					/>
					, null
				)
				, AppTableCell.initWithTitle(
					" "
					, null
				)
			]
			, ["android-app-specification-row"]
		)
	);

	aRowArray.push(
		new AppTableRow(
			[
				AppTableCell.initWithView(
					<div className="app-separator-horizontal"></div>
					, null
				)
			]
			, ["ios-app-specification-row", "product-details-row-separator"]
		)
	);

	aRowArray.push(
		new AppTableRow(
			[
				AppTableCell.initWithView(
					<AppTextField
						title={LocalizationManager.productDetailsComponent_androidAppMinimumSupportedVersionInputTitle() + " :"}
						placeholder={LocalizationManager.productDetailsComponent_androidAppMinimumSupportedVersionInputPlaceholder()}
						inputClassNames={["product-details-text-field"]}
						ref={this.androidAppMinimumSupportedVersionTextField}
					/>
					, null
				)
				, AppTableCell.initWithView(
					<AppTextField
						title={LocalizationManager.productDetailsComponent_androidAppLatestVersionInputTitle() + " :"}
						placeholder={LocalizationManager.productDetailsComponent_androidAppLatestVersionInputPlaceholder()}
						inputClassNames={["product-details-text-field"]}
						ref={this.androidAppLatestVersionTextField}
					/>
					, null
				)
			]
			, ["android-app-specification-row"]
		)
	);

	aRowArray.push(
		new AppTableRow(
			[
				AppTableCell.initWithView(
					<AppTextField
						title={LocalizationManager.productDetailsComponent_androidAppDownloadUrlInputTitle() + " :"}
						placeholder={LocalizationManager.productDetailsComponent_androidAppDownloadUrlInputPlaceholder()}
						inputClassNames={["product-details-text-field"]}
						ref={this.androidAppDownloadUrlTextField}
					/>
					, null
				)
				, AppTableCell.initWithTitle(
					" "
					, null
				)
			]
			, ["android-app-specification-row"]
		)
	);

	aRowArray.push(
		new AppTableRow(
			[
				AppTableCell.initWithView(
					<div className="app-separator-horizontal"></div>
					, null
				)
			]
			, ["ios-app-specification-row", "product-details-row-separator"]
		)
	);

	aRowArray.push(
		new AppTableRow(
			[
				AppTableCell.initWithView(
					<AppButton
						title={LocalizationManager.productDetailsComponent_androidAppSubmitButtonTitle()}
						type={AppButtonType.arcRect}
						didSelectCallback={this.didSelectUpdateAndroidAppSpecification.bind(this)}
						inputClassNames={["product-details-arc-button"]}
					/>
					, ["android-app-specification-cell"]
				)
				, AppTableCell.initWithTitle(
					" "
					, null
				)
			]
			, ["android-app-specification-row"]
		)
	);

	var aSection = [];
	if (this.isLoggedInCustomerOwnerOfCurrentProduct()) {
		aSection.push(<AppSpacer height="40px" key="android_app_top_space" />);
		aSection.push(<div className="product-details-section-title" key="android_app_title">{LocalizationManager.productDetailsComponent_androidAppSectionTitle()}</div>);
		aSection.push(<AppSpacer height="8px" key="android_app_line_space" />);
		aSection.push(<AppTable data={aRowArray} key="android_app_list" />);
	}

	aReturnVal = aSection;

	return aReturnVal;
}

ProductDetailsComponent.prototype.reloadAndroidAppSpecificationView = function() {
	if (this.product != null && this.product.androidAppSpecification != null && this.androidAppBundleIdentifierTextField.current != null) {
		this.androidAppBundleIdentifierTextField.current.value = this.product.androidAppSpecification.bundleIdentifier;
		this.androidAppMinimumSupportedVersionTextField.current.value = this.product.androidAppSpecification.minimumSupportedVersion;
		this.androidAppLatestVersionTextField.current.value = this.product.androidAppSpecification.latestVersion;
		this.androidAppDownloadUrlTextField.current.value = this.product.androidAppSpecification.downloadUrl;
	}
}


// MARK:- Actions

ProductDetailsComponent.prototype.didSelectUpdateProduct = function() {
	this.updateProduct();
}

ProductDetailsComponent.prototype.didSelectDeleteProductCustomerMap = function(pProductCustomerMapUuid) {
	var aThis = this;
	PopupManager.shared.displayConfirmationMessage("Do you want to remove the selected member?", function() {
		aThis.deleteProductCustomerMap(pProductCustomerMapUuid);
	})
}

ProductDetailsComponent.prototype.didSelectDeleteProductInvitation = function(pProductInvitationUuid) {
	var aThis = this;
	PopupManager.shared.displayConfirmationMessage("Do you want to remove the selected invitation?", function() {
		aThis.deleteProductInvitation(pProductInvitationUuid);
	})
}

ProductDetailsComponent.prototype.didSelectNewProductInvitation = function() {
	this.newProductInvitation();
}

ProductDetailsComponent.prototype.didSelectNewProductApiKey = function() {
	this.newProductApiKey();
}

ProductDetailsComponent.prototype.didSelectUpdateIosAppSpecification = function() {
	var aThis = this;

	this.updateIosAppSpecification()
}

ProductDetailsComponent.prototype.didSelectUpdateAndroidAppSpecification = function() {
	var aThis = this;

	aThis.updateAndroidAppSpecification();
}


// MARK:- Local Operations

ProductDetailsComponent.prototype.isLoggedInCustomerOwnerOfCurrentProduct = function() {
	var aReturnVal = false;
	if (this.productCustomerMaps instanceof Array) {
		var anOwnerProductCustomerMapArray = this.productCustomerMaps.filter((pProductCustomerMap) => {
			return pProductCustomerMap.role.equals(ProductCustomerRole.owner)
		});
		if (anOwnerProductCustomerMapArray.length > 0 && anOwnerProductCustomerMapArray[0].customer.uuid === CacheManager.shared.loggedInCustomer.uuid) {
			aReturnVal = true;
		}
	}
	return aReturnVal;
}

ProductDetailsComponent.prototype.formattedSha1Hash = function(pSha1Hash) {
	var aReturnVal = pSha1Hash;
	if (aReturnVal != null && aReturnVal.length > 7) {
		aReturnVal = aReturnVal.substring(0, 7);
	}
	if (aReturnVal != null && aReturnVal.length > 0) {
		aReturnVal = aReturnVal + " (SHA1)"
	}
	return aReturnVal;
}


// MARK:- Data Manager

ProductDetailsComponent.prototype.productDetails = function(pCompletion) {
	var aProductUuid = this.props.params.uuid;
	var aThis = this;

	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.productDetails(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			if (pResult.outcome instanceof Product) {
				aThis.product = pResult.outcome;
			} else {
				aThis.product = null;
			}
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			var aMessage = LocalizationManager.common_unknownErrorMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		}
		aThis.reloadAllViews();
		if (pCompletion != null) {
			pCompletion(pResult.signet);
		}
	}, aProductUuid);
}

ProductDetailsComponent.prototype.updateProduct = function() {
	var aProduct = new Product()
	aProduct.uuid = this.props.params.uuid;
	aProduct.title = this.productTitleTextField.current.value;

	var aThis = this;

	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.updateProduct(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			var aMessage = LocalizationManager.common_apiResponseSuccessMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displaySuccessMessage(aMessage);
			aThis.reloadAllData();
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			var aMessage = LocalizationManager.common_unknownErrorMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		}
		aThis.reloadAllViews();
	}, aProduct);
}

ProductDetailsComponent.prototype.searchProductCustomerMap = function(pCompletion) {
	var aProductUuid = this.props.params.uuid;
	var aThis = this;

	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.searchProductCustomerMap(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			if (pResult.outcome instanceof Array) {
				aThis.productCustomerMaps = pResult.outcome;
				var aFilteredProductCustomerMap = aThis.productCustomerMaps.find(pElement => {
					return pElement.customer.uuid === CacheManager.shared.loggedInCustomer.uuid;
				});
				if (aFilteredProductCustomerMap != null
					&& aFilteredProductCustomerMap.role != null
					&& aFilteredProductCustomerMap.role.equals(ProductCustomerRole.owner)) {
					aThis.isLoggedInUserOwner = true;
				} else {
					aThis.isLoggedInUserOwner = false;
				}
			} else {
				aThis.productCustomerMaps = null;
			}
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			var aMessage = LocalizationManager.common_unknownErrorMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		}
		if (pCompletion != null) {
			pCompletion(pResult.signet);
		}
		aThis.reloadAllViews();
	}, aProductUuid);
}

ProductDetailsComponent.prototype.searchProductInvitation = function(pCompletion) {
	var aProductUuid = this.props.params.uuid;
	var aThis = this;

	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.searchProductInvitation(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			if (pResult.outcome instanceof Array) {
				aThis.productInvitations = pResult.outcome;
			} else {
				aThis.productInvitations = null;
			}
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			var aMessage = LocalizationManager.common_unknownErrorMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		}
		if (pCompletion != null) {
			pCompletion(pResult.signet);
		}
		aThis.reloadAllViews();
	}, aProductUuid);
}

ProductDetailsComponent.prototype.deleteProductCustomerMap = function(pProductCustomerMapUuid) {
	var aProductCustomerMapUuid = pProductCustomerMapUuid;
	var aThis = this;

	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.deleteProductCustomerMap(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			var aMessage = LocalizationManager.common_apiResponseSuccessMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displaySuccessMessage(aMessage);
			aThis.reloadAllData();
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			var aMessage = LocalizationManager.common_unknownErrorMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		}
		aThis.reloadAllViews();
	}, aProductCustomerMapUuid);
}

ProductDetailsComponent.prototype.deleteProductInvitation = function(pProductInvitationUuid) {
	var aProductInvitationUuid = pProductInvitationUuid;
	var aThis = this;

	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.deleteProductInvitation(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			var aMessage = LocalizationManager.common_apiResponseSuccessMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displaySuccessMessage(aMessage);
			aThis.reloadAllData();
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			var aMessage = LocalizationManager.common_unknownErrorMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		}
		aThis.reloadAllViews();
	}, aProductInvitationUuid);
}

ProductDetailsComponent.prototype.newProductInvitation = function() {
	var aThis = this;

	var aProductInvitation = new ProductInvitation();
	aProductInvitation.emailAddress = this.invitationEmailAddressTextField.current.value;
	var aProduct = new Product();
	aProduct.uuid = this.product.uuid;
	aProductInvitation.product = aProduct;
	aProductInvitation.role = ProductCustomerRole.agent;

	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.newProductInvitation(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			var aMessage = LocalizationManager.common_apiResponseSuccessMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displaySuccessMessage(aMessage);
			aThis.resetAllViews();
			aThis.reloadAllData();
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			var aMessage = LocalizationManager.common_unknownErrorMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		}
		aThis.reloadAllViews();
	}, aProductInvitation);
}

ProductDetailsComponent.prototype.newProductApiKey = function() {
	var aThis = this;

	var aProductUuid = this.product.uuid;

	var aKeyPair = ATEncryptionManager.generateRsaKeyPair();
	var anRsaPrivateKey = aKeyPair.privateKey;
	var anRsaPublicKey = aKeyPair.publicKey;

	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.newProductApiKey(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			aThis.promptToSaveApiKeyFile(aProductUuid, aThis.product.title, anRsaPrivateKey, anRsaPublicKey)
			var aMessage = LocalizationManager.common_apiResponseSuccessMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displaySuccessMessage(aMessage);
			aThis.resetAllViews();
			aThis.reloadAllData();
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			var aMessage = LocalizationManager.common_unknownErrorMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		}
		aThis.reloadAllViews();
	}, aProductUuid, anRsaPublicKey);
}

ProductDetailsComponent.prototype.promptToSaveApiKeyFile = function(pProductUuid, pProductTitle, pRsaPrivateKey, pRsaPublicKey) {
	var anApiKeyDict = new Object();
	anApiKeyDict.productUuid = pProductUuid;
	anApiKeyDict.rsaPrivateKey = pRsaPrivateKey;
	anApiKeyDict.rsaPublicKey = pRsaPublicKey;

	var anApiKeyJson = JSON.stringify(anApiKeyDict);
	var anApiKey = ATEncryptionManager.encodeBase64(anApiKeyJson);

	var aFileName = pProductTitle.toLowerCase() + "-apikey.txt";
	aFileName = aFileName.replace(/\s/g,"-");
	ATUtilityManager.saveAsFile(anApiKey, aFileName);
}

ProductDetailsComponent.prototype.updateIosAppSpecification = function() {
	var anIosAppSpecification = new IosAppSpecification()
	anIosAppSpecification.bundleIdentifier = this.iosAppBundleIdentifierTextField.current.value;
	anIosAppSpecification.minimumSupportedVersion = this.iosAppMinimumSupportedVersionTextField.current.value;
	anIosAppSpecification.latestVersion = this.iosAppLatestVersionTextField.current.value;
	anIosAppSpecification.downloadUrl = this.iosAppDownloadUrlTextField.current.value;

	var aRequestParameterSignetArray = [];
	aRequestParameterSignetArray.push(RequestParameter.iosAppBundleIdentifier);
	aRequestParameterSignetArray.push(RequestParameter.iosAppMinimumSupportedVersion);
	aRequestParameterSignetArray.push(RequestParameter.iosAppLatestVersion);
	aRequestParameterSignetArray.push(RequestParameter.iosAppDownloadUrl);

	var aProductUuid = this.product.uuid;

	var aThis = this;

	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.updateIosAppSpecification(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			var aMessage = LocalizationManager.common_apiResponseSuccessMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displaySuccessMessage(aMessage);
			aThis.reloadAllData();
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			var aMessage = LocalizationManager.common_unknownErrorMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		}
		aThis.reloadAllViews();
	}, anIosAppSpecification, aProductUuid, aRequestParameterSignetArray);
}

ProductDetailsComponent.prototype.updateAndroidAppSpecification = function() {
	var anAndroidAppSpecification = new AndroidAppSpecification()
	anAndroidAppSpecification.bundleIdentifier = this.androidAppBundleIdentifierTextField.current.value;
	anAndroidAppSpecification.minimumSupportedVersion = this.androidAppMinimumSupportedVersionTextField.current.value;
	anAndroidAppSpecification.latestVersion = this.androidAppLatestVersionTextField.current.value;
	anAndroidAppSpecification.downloadUrl = this.androidAppDownloadUrlTextField.current.value;

	var aRequestParameterSignetArray = [];
	aRequestParameterSignetArray.push(RequestParameter.androidAppBundleIdentifier);
	aRequestParameterSignetArray.push(RequestParameter.androidAppMinimumSupportedVersion);
	aRequestParameterSignetArray.push(RequestParameter.androidAppLatestVersion);
	aRequestParameterSignetArray.push(RequestParameter.androidAppDownloadUrl);

	var aProductUuid = this.product.uuid;

	var aThis = this;

	ProgressIndicationManager.shared.showLoadingOverlay();
	DataTransferManager.shared.updateAndroidAppSpecification(function(pResult) {
		ProgressIndicationManager.shared.hideLoadingOverlay();
		if (pResult.signet === DataTransferManagerResultSignet.success) {
			var aMessage = LocalizationManager.common_apiResponseSuccessMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displaySuccessMessage(aMessage);
			aThis.reloadAllData();
		} else if (pResult.signet === DataTransferManagerResultSignet.errorAuthenticationNeeded) {
			RoutingManager.shared.gotoSignUpLoginCustomer();
		} else {
			var aMessage = LocalizationManager.common_unknownErrorMessage();
			if (pResult.localizedDescription != null) {
				aMessage = pResult.localizedDescription;
			}
			PopupManager.shared.displayErrorMessage(aMessage);
		}
		aThis.reloadAllViews();
	}, anAndroidAppSpecification, aProductUuid, aRequestParameterSignetArray);
}


export default withParams(ProductDetailsComponent);
