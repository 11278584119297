import { LocalizationManager } from './localization-manager';

LocalizationManager.searchPlanComponent_header01 = function() {
	return LocalizationManager.localizedString("searchPlanComponent_header01");
}

LocalizationManager.searchPlanComponent_header02 = function() {
	return LocalizationManager.localizedString("searchPlanComponent_header02");
}


LocalizationManager.searchPlanComponent_subheader01 = function() {
	return LocalizationManager.localizedString("searchPlanComponent_subheader01");
}

LocalizationManager.searchPlanComponent_subheader02 = function() {
	return LocalizationManager.localizedString("searchPlanComponent_subheader02");
}

LocalizationManager.searchPlanComponent_loginToPurchaseButtonTitle = function() {
	return LocalizationManager.localizedString("searchPlanComponent_loginToPurchaseButtonTitle");
}

LocalizationManager.searchPlanComponent_gotoSubscriptionDetailsToPurchaseButtonTitle = function() {
	return LocalizationManager.localizedString("searchPlanComponent_gotoSubscriptionDetailsToPurchaseButtonTitle");
}


LocalizationManager.searchPlanComponent_basicPlanName = function() {
	return LocalizationManager.localizedString("searchPlanComponent_basicPlanName");
}

LocalizationManager.searchPlanComponent_basicPlanNote = function() {
	return LocalizationManager.localizedString("searchPlanComponent_basicPlanNote");
}

LocalizationManager.searchPlanComponent_basicPlanAmount = function() {
	return LocalizationManager.localizedString("searchPlanComponent_basicPlanAmount");
}

LocalizationManager.searchPlanComponent_basicPlanDetails01 = function() {
	return LocalizationManager.localizedString("searchPlanComponent_basicPlanDetails01");
}

LocalizationManager.searchPlanComponent_basicPlanDetails02 = function() {
	return LocalizationManager.localizedString("searchPlanComponent_basicPlanDetails02");
}


LocalizationManager.searchPlanComponent_premiumPlanName = function() {
	return LocalizationManager.localizedString("searchPlanComponent_premiumPlanName");
}

LocalizationManager.searchPlanComponent_premiumPlanNote = function() {
	return LocalizationManager.localizedString("searchPlanComponent_premiumPlanNote");
}

LocalizationManager.searchPlanComponent_premiumPlanAmountMonthly = function(pCurrencySymbol, pAmount) {
	return LocalizationManager.localizedString("searchPlanComponent_premiumPlanAmountMonthly", pCurrencySymbol, pAmount);
}

LocalizationManager.searchPlanComponent_premiumPlanAmountYearly = function(pCurrencySymbol, pAmount) {
	return LocalizationManager.localizedString("searchPlanComponent_premiumPlanAmountYearly", pCurrencySymbol, pAmount);
}

LocalizationManager.searchPlanComponent_premiumPlanDetails01 = function() {
	return LocalizationManager.localizedString("searchPlanComponent_premiumPlanDetails01");
}

LocalizationManager.searchPlanComponent_premiumPlanDetails02 = function() {
	return LocalizationManager.localizedString("searchPlanComponent_premiumPlanDetails02");
}

LocalizationManager.searchPlanComponent_premiumPlanDetails03 = function() {
	return LocalizationManager.localizedString("searchPlanComponent_premiumPlanDetails03");
}


LocalizationManager.searchPlanComponent_freeForever_header01 = function() {
	return LocalizationManager.localizedString("searchPlanComponent_freeForever_header01");
}

LocalizationManager.searchPlanComponent_freeForever_header02 = function() {
	return LocalizationManager.localizedString("searchPlanComponent_freeForever_header02");
}


LocalizationManager.searchPlanComponent_freeForever_subheader01 = function() {
	return LocalizationManager.localizedString("searchPlanComponent_freeForever_subheader01");
}

LocalizationManager.searchPlanComponent_freeForever_subheader02 = function() {
	return LocalizationManager.localizedString("searchPlanComponent_freeForever_subheader02");
}


LocalizationManager.searchPlanComponent_freeForever_basicPlanName = function() {
	return LocalizationManager.localizedString("searchPlanComponent_freeForever_basicPlanName");
}

LocalizationManager.searchPlanComponent_freeForever_basicPlanNote = function() {
	return LocalizationManager.localizedString("searchPlanComponent_freeForever_basicPlanNote");
}

LocalizationManager.searchPlanComponent_freeForever_basicPlanAmount = function() {
	return LocalizationManager.localizedString("searchPlanComponent_freeForever_basicPlanAmount");
}

LocalizationManager.searchPlanComponent_freeForever_basicPlanTagline = function() {
	return LocalizationManager.localizedString("searchPlanComponent_freeForever_basicPlanTagline");
}


LocalizationManager.searchPlanComponent_freeForever_basicPlanDetails01 = function() {
	return LocalizationManager.localizedString("searchPlanComponent_freeForever_basicPlanDetails01");
}

LocalizationManager.searchPlanComponent_freeForever_basicPlanDetails02 = function() {
	return LocalizationManager.localizedString("searchPlanComponent_freeForever_basicPlanDetails02");
}

LocalizationManager.searchPlanComponent_freeForever_basicPlanDetails03 = function() {
	return LocalizationManager.localizedString("searchPlanComponent_freeForever_basicPlanDetails03");
}

LocalizationManager.searchPlanComponent_freeForever_basicPlanDetails04 = function() {
	return LocalizationManager.localizedString("searchPlanComponent_freeForever_basicPlanDetails04");
}


LocalizationManager.subscriptionDetailsComponent_currentSubscriptionBasicPlanTitle = function() {
	return LocalizationManager.localizedString("subscriptionDetailsComponent_currentSubscriptionBasicPlanTitle");
}

LocalizationManager.subscriptionDetailsComponent_currentSubscriptionBasicPlanTagLine = function() {
	return LocalizationManager.localizedString("subscriptionDetailsComponent_currentSubscriptionBasicPlanTagLine");
}

LocalizationManager.subscriptionDetailsComponent_currentSubscriptionPremiumPlanTitle = function() {
	return LocalizationManager.localizedString("subscriptionDetailsComponent_currentSubscriptionPremiumPlanTitle");
}

LocalizationManager.subscriptionDetailsComponent_currentSubscriptionPremiumPlanTagLine = function() {
	return LocalizationManager.localizedString("subscriptionDetailsComponent_currentSubscriptionPremiumPlanTagLine");
}

LocalizationManager.subscriptionDetailsComponent_currentSubscriptionPremiumPlanBenefit01 = function() {
	return LocalizationManager.localizedString("subscriptionDetailsComponent_currentSubscriptionPremiumPlanBenefit01");
}

LocalizationManager.subscriptionDetailsComponent_currentSubscriptionPremiumPlanBenefit02 = function() {
	return LocalizationManager.localizedString("subscriptionDetailsComponent_currentSubscriptionPremiumPlanBenefit02");
}

LocalizationManager.subscriptionDetailsComponent_currentSubscriptionPremiumPlanBenefit03 = function() {
	return LocalizationManager.localizedString("subscriptionDetailsComponent_currentSubscriptionPremiumPlanBenefit03");
}

LocalizationManager.subscriptionDetailsComponent_needMore = function() {
	return LocalizationManager.localizedString("subscriptionDetailsComponent_needMore");
}

LocalizationManager.subscriptionDetailsComponent_upgradeToPremium = function() {
	return LocalizationManager.localizedString("subscriptionDetailsComponent_upgradeToPremium");
}

LocalizationManager.subscriptionDetailsComponent_premiumPlanTagLine = function() {
	return LocalizationManager.localizedString("subscriptionDetailsComponent_premiumPlanTagLine");
}

LocalizationManager.subscriptionDetailsComponent_premiumPlanKnowMore = function() {
	return LocalizationManager.localizedString("subscriptionDetailsComponent_premiumPlanKnowMore");
}

LocalizationManager.subscriptionDetailsComponent_premiumPlanBoxTitle = function() {
	return LocalizationManager.localizedString("subscriptionDetailsComponent_premiumPlanBoxTitle");
}

LocalizationManager.subscriptionDetailsComponent_premiumPlanBoxDuration = function() {
	return LocalizationManager.localizedString("subscriptionDetailsComponent_premiumPlanBoxDuration");
}

LocalizationManager.subscriptionDetailsComponent_premiumPlanBoxTotalTitle = function() {
	return LocalizationManager.localizedString("subscriptionDetailsComponent_premiumPlanBoxTotalTitle");
}

LocalizationManager.subscriptionDetailsComponent_contactUsForPaymentOptions = function() {
	return LocalizationManager.localizedString("subscriptionDetailsComponent_contactUsForPaymentOptions");
}
