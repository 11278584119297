import { LocalizationManager } from './localization-manager';

LocalizationManager.homePageComponent_headerAppName = function() {
	return LocalizationManager.localizedString("homePageComponent_headerAppName");
}

LocalizationManager.homePageComponent_headerAppTagLine = function() {
	return LocalizationManager.localizedString("homePageComponent_headerAppTagLine");
}


LocalizationManager.homePageComponent_subheader = function() {
	return LocalizationManager.localizedString("homePageComponent_subheader");
}


LocalizationManager.homePageComponent_featureHeader = function() {
	return LocalizationManager.localizedString("homePageComponent_featureHeader");
}

LocalizationManager.homePageComponent_featureSummary = function() {
	return LocalizationManager.localizedString("homePageComponent_featureSummary");
}


LocalizationManager.homePageComponent_featureAppUpdatePopupTitle = function() {
	return LocalizationManager.localizedString("homePageComponent_featureAppUpdatePopupTitle");
}

LocalizationManager.homePageComponent_featureAppUpdatePopupSummary01 = function() {
	return LocalizationManager.localizedString("homePageComponent_featureAppUpdatePopupSummary01");
}

LocalizationManager.homePageComponent_featureAppUpdatePopupSummary02 = function() {
	return LocalizationManager.localizedString("homePageComponent_featureAppUpdatePopupSummary02");
}

LocalizationManager.homePageComponent_watchOnYoutubeButtonTitle = function() {
	return LocalizationManager.localizedString("homePageComponent_watchOnYoutubeButtonTitle");
}

LocalizationManager.homePageComponent_getStartedButtonTitle = function() {
	return LocalizationManager.localizedString("homePageComponent_getStartedButtonTitle");
}


LocalizationManager.homePageComponent_featureEasyIntegrationTitle = function() {
	return LocalizationManager.localizedString("homePageComponent_featureEasyIntegrationTitle");
}

LocalizationManager.homePageComponent_featureEasyIntegrationSummary01 = function() {
	return LocalizationManager.localizedString("homePageComponent_featureEasyIntegrationSummary01");
}
