import { DataTransferManagerResult, DataTransferManagerResultSignet } from './data-transfer-manager';
import { CacheManager } from '../collaborators/cache-manager';
import { DataContractManagerHttp } from './data-contract-manager-http';
import { ConfigurationManager } from '../collaborators/configuration-manager';
import { RequestParameter } from '../models/request-parameter';
import { LocalizationManager } from '../localization-manager/localization-manager';


class DataTransferManager_HttpRequest {
	title = null;
	url = null;
	method = null;
	headerDict = null;
	body = null;

	constructor(title, url, method, headerDict, body) {
		this.title = title;
		this.url = url;
		this.method = method;
		this.headerDict = headerDict;
		this.body = body;
	}
}

class DataTransferManager_HttpResponse {
	code = null;
	headerDict = null;
	bodyData = null;

	constructor(code, headerDict, bodyData) {
		this.code = code;
		this.headerDict = headerDict;
		this.bodyData = bodyData;
	}
}


class DataTransferManagerHttp {

	_commonHeaders(pShouldIncludeAuthorization = false) {
		var aReturnVal = {
			"Accept": "application/json"
			, "Accept-Language": LocalizationManager.currentLocale.signet()
		};
		if (pShouldIncludeAuthorization) {
			aReturnVal["Authorization"] = ("Bearer " + CacheManager.shared.session.token);
		}
		return aReturnVal;
	}


	/**
	 * @callback DataTransferManagerHttp_RequestCreator
	 * @return {DataTransferManager_HttpRequest} An HTTP request.
	 * 
	 * @callback DataTransferManagerHttp_ResponseMapper
	 * @return {DataTransferManager_HttpResponse} An HTTP response.
	 * 
	 * @callback DataTransferManagerResultCallback
	 * @return {DataTransferManagerResult} A result.
	 * 
	 * **
	 * @param {DataTransferManagerHttp_RequestCreator} pRequestCreator
	 * @param {DataTransferManagerHttp_ResponseMapper} pResponseMapper
	 * @param {DataTransferManagerResultCallback} pCallback
	 */
	_sendRequest(pRequestCreator, pResponseMapper, pCallback) {
		var aThis = this;

		// Create request
		let anHttpRequest = pRequestCreator();

		// Send request
		fetch(anHttpRequest.url, {
			method: anHttpRequest.method
			, headers: anHttpRequest.headerDict
			, body: anHttpRequest.body
		})
		.then(function(pResponse) {
			if (pResponse.status === 404) {
				var anError = new Error(pResponse.statusText);
				anError.name = "PageNotFound";
				anError.message = "Page not found.";
				throw anError;
			}
			return pResponse.text();
		})
		.then(function(pResponseData) {
			var aDataManagerResult = pResponseMapper(new DataTransferManager_HttpResponse(null, null, pResponseData));
			pCallback(aDataManagerResult);
		}).catch(function(pError) {
			var aLocalizedDescription = null;
			if (pError.name === "TypeError" && pError.message === "Failed to fetch") {
				aLocalizedDescription = "Can not connect to server.";
			} else if (pError.message != null) {
				aLocalizedDescription = pError.message;
			} else {
				aLocalizedDescription = "Unknown error.";
			}

			var aResult = new DataTransferManagerResult();
			aResult.code = 1;
			aResult.localizedDescription = aLocalizedDescription
			aResult.outcome = null;
			return aResult;
		});
	}


	registerCustomer(pCallback, pCustomer) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "registerCustomer";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(false);
				var aPayloadDict = {
					"requestPurposeSignet" : "register_customer"
					, "emailAddress" : pCustomer.emailAddress
					, "displayName" : pCustomer.displayName
					, "passwordHash" : pCustomer.passwordHash
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.registerCustomerResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	loginCustomer(pCallback, pCustomer) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "loginCustomer";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(false);
				var aPayloadDict = {
					"requestPurposeSignet" : "login_customer"
					, "emailAddress" : pCustomer.emailAddress
					, "encryptedPassword" : pCustomer.encryptedPassword
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.loginCustomerResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	logoutCustomer(pCallback, pCustomer) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "logoutCustomer";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "logout_customer"
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.loginCustomerResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	newResetCustomerPasswordCode(pCallback, pCustomer) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "newResetCustomerPasswordCode";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(false);
				var aPayloadDict = {
					"requestPurposeSignet" : "new_reset_customer_password_code"
					, "emailAddress" : pCustomer.emailAddress
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.newResetCustomerPasswordCodeResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	resetCustomerPassword(pCallback, pCustomer) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "resetCustomerPassword";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(false);
				var aPayloadDict = {
					"requestPurposeSignet" : "reset_customer_password"
					, "emailAddress" : pCustomer.emailAddress
					, "resetPasswordCodeHash" : pCustomer.resetPasswordCodeHash
					, "passwordHash" : pCustomer.passwordHash
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.resetCustomerPasswordResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	profileDetails(pCallback, pCustomer) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "profileDetails";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "profile_details"
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.profileDetailsResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	updateCustomerProfile(pCallback, pCustomer) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "updateCustomerProfile";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "update_customer_profile"
					, "displayName" : pCustomer.displayName
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.updateCustomerProfileResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	updateCustomerPassword(pCallback, pCustomer) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "updateCustomerPassword";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "update_customer_password"
					, "encryptedPassword" : pCustomer.encryptedPassword
					, "passwordHash" : pCustomer.newPasswordHash
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.updateCustomerProfileResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	newCustomerIdentity(pCallback, pCustomerIdentity) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "newCustomerIdentity";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "new_customer_identity"
					, "emailAddress" : pCustomerIdentity.value
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.newCustomerIdentityResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	verifyCustomerIdentity(pCallback, pCustomerIdentity) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "verifyCustomerIdentity";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "verify_customer_identity"
					, "emailAddress" : pCustomerIdentity.value
					, "verificationCodeHash" : pCustomerIdentity.verificationCodeHash
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.verifyCustomerIdentityResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	newVerifyCustomerIdentityCode(pCallback, pCustomerIdentity) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "newVerifyCustomerIdentityCode";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "new_verify_customer_identity_code"
					, "customerIdentityUuid" : pCustomerIdentity.uuid
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.verifyCustomerIdentityResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	deleteCustomerIdentity(pCallback, pCustomerIdentity) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "deleteCustomerIdentity";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "delete_customer_identity"
					, "customerIdentityUuid" : pCustomerIdentity.uuid
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.deleteCustomerIdentityResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	newProduct(pCallback, pProduct) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "newProduct";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "new_product"
					, "productTitle" : pProduct.title
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.newProductResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	productDetails(pCallback, pProductUuid) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "productDetails";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "product_details"
					, "productUuid" : pProductUuid
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.productDetailsResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	updateProduct(pCallback, pProduct) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "updateProduct";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "update_product"
					, "productUuid" : pProduct.uuid
					, "productTitle" : pProduct.title
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.updateProductResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	updateIosAppSpecification(pCallback, pIosAppSpecification, pProductUuid, pRequestParameterArray) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "updateIosAppSpecification";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "update_ios_app_specification"
					, "requestParameterSignetCsv" : RequestParameter.signetCsv(pRequestParameterArray)
					, "productUuid" : pProductUuid
					, "iosAppBundleIdentifier" : pIosAppSpecification.bundleIdentifier
					, "iosAppMinimumSupportedVersion" : pIosAppSpecification.minimumSupportedVersion
					, "iosAppLatestVersion" : pIosAppSpecification.latestVersion
					, "iosAppDownloadUrl" : pIosAppSpecification.downloadUrl
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.updateProductResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	updateAndroidAppSpecification(pCallback, pAndroidAppSpecification, pProductUuid, pRequestParameterArray) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "updateAndroidAppSpecification";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "update_android_app_specification"
					, "requestParameterSignetCsv" : RequestParameter.signetCsv(pRequestParameterArray)
					, "productUuid" : pProductUuid
					, "androidAppBundleIdentifier" : pAndroidAppSpecification.bundleIdentifier
					, "androidAppMinimumSupportedVersion" : pAndroidAppSpecification.minimumSupportedVersion
					, "androidAppLatestVersion" : pAndroidAppSpecification.latestVersion
					, "androidAppDownloadUrl" : pAndroidAppSpecification.downloadUrl
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.updateProductResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	searchProductCustomerMap(pCallback, pProductUuid, pAssigneeUuid) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "searchProductCustomerMap";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "search_product_customer_map"
					, "productUuid" : pProductUuid
					, "assigneeUuid" : pAssigneeUuid
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.searchProductCustomerMapResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	deleteProductCustomerMap(pCallback, pProductCustomerMapUuid) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "deleteProductCustomerMap";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "delete_product_customer_map"
					, "productCustomerMapUuid" : pProductCustomerMapUuid
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.deleteProductCustomerMapResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	newProductInvitation(pCallback, pProductInvitation) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "newProductInvitation";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "new_product_invitation"
					, "emailAddress" : pProductInvitation.emailAddress
					, "productUuid" : pProductInvitation.product.uuid
					, "productCustomerRoleSignet" : pProductInvitation.role.signet()
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.newProductInvitationResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	searchProductInvitation(pCallback, pProductUuid) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "searchProductInvitation";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "search_product_invitation"
					, "productUuid" : pProductUuid
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.searchProductInvitationResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	deleteProductInvitation(pCallback, pProductInvitationUuid) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "deleteProductInvitation";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "delete_product_invitation"
					, "productInvitationUuid" : pProductInvitationUuid
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.deleteProductCustomerMapResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	acceptProductInvitation(pCallback, pProductInvitationUuid) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "acceptProductInvitation";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "accept_product_invitation"
					, "productInvitationUuid" : pProductInvitationUuid
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.acceptProductInvitationResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	newProductApiKey(pCallback, pProductUuid, pRsaPublicKey) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "newProductApiKey";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "new_product_api_key"
					, "productUuid" : pProductUuid
					, "rsaPublicKey" : pRsaPublicKey
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.newProductApiKeyResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}


	newPayment(pCallback, pPayment) {
		var aThis = this;

		// Send request
		aThis._sendRequest(
			function() {
				var aTitle = "newPayment";
				var aMethod = "POST";
				var aHeaderDict = aThis._commonHeaders(true);
				var aPayloadDict = {
					"requestPurposeSignet" : "new_payment"
					, "paypalOrderId" : pPayment.paypalOrderId
				};
				var aBodyDict = {
					"payload" : aPayloadDict
				};
				return new DataTransferManager_HttpRequest(
					aTitle
					, ConfigurationManager.appApiBaseUrl
					, aMethod
					, aHeaderDict
					, JSON.stringify(aBodyDict)
				)
			}
			, function(pDataTransferManager_HttpResponse) {
				return DataContractManagerHttp.newPaymentResult(pDataTransferManager_HttpResponse.bodyData);
			}
			, pCallback
		)
	}

}


export {DataTransferManagerHttp}
