import React, { Component } from 'react';
import { AppSpacer } from '../app-ui-kit/app-ui-kit';
import { ObservationManager, ObservationEventName } from '../../collaborators/observation-manager';
import { ATMarkdownView } from "../../third-party/com-aurvan-atkit-reactjs/src/at-markdown-view";
import FoundationComponent from "../foundation/foundation-component";

import "./search-knowledgebase-component.css"
// TODO: Update demo videos
import force_update_popup_demo from "../../assets/helpbook/force-update-popup-demo.mp4";
import latest_update_popup_demo from "../../assets/helpbook/latest-update-popup-demo.mp4";
import install_sahayakclient_spm_01 from "../../assets/helpbook/install-sahayakclient-spm-01.png";
import install_sahayakclient_spm_02 from "../../assets/helpbook/install-sahayakclient-spm-02.png";
import install_sahayakclient_spm_03 from "../../assets/helpbook/install-sahayakclient-spm-03.png";
import new_product_01 from "../../assets/helpbook/new-product-01.png";
import new_api_key_01 from "../../assets/helpbook/new-api-key-01.png";
import new_api_key_02 from "../../assets/helpbook/new-api-key-02.png";
import app_details_01 from "../../assets/helpbook/app-details-01.png";


class SearchKnowledgebaseComponent extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.reloadAllViews();

		var aThis = this;
		ObservationManager.shared.addObserver(function() {
			aThis.reloadAllData();
		}, ObservationEventName.didFinishReloadLoggedInUser);
	}

	reloadAllData() {
		this.reloadAllViews();
	}

	reloadAllViews() {
		this.forceUpdate();
	}

	render() {
		return (
			<div className="foundation-storey vertical-scroll-view search-knowledgebase-storey">

				<div className="search-knowledgebase-page">

					<ATMarkdownView># Introduction</ATMarkdownView>
					{/* <ATMarkdownView>Sahayak is a lightweight Plug-and-Play (PnP) library designed to provide various accessory features in your mobile app so that you can focus on developing main functionality of your product.</ATMarkdownView> */}
					<ATMarkdownView>Sahayak provides both Backend System (as SAAS) and Frontend System (as libraries to integrate in your mobile app) to provide below listed features in your mobile app,</ATMarkdownView>
					<ATMarkdownView>1. Force Update Popup [Read More]("#feature_force_update_popup")</ATMarkdownView>
					{/* <ATMarkdownView>2. Help Desk Support Software [Read More]("#feature_help_desk_support_software")</ATMarkdownView> */}


					<ATMarkdownView># [Feature: App Update Popup](#feature_force_update_popup)</ATMarkdownView>
					<ATMarkdownView>You can set the minimum supported version and the latest available version of your mobile app in the **Sahayak** portal. Then integrate **SahayakClient** mobile library (iOS and Android) in your mobile app (with only 2 lines of code). Thats app, and your users will see the app update popup automatically.</ATMarkdownView>

					<ATMarkdownView>## Demo: Force Update Popup</ATMarkdownView>

					<div className="search-knowledgebase-screenshot-container">
					<video controls className="search-knowledgebase-screenshot">
						<source src={force_update_popup_demo} type="video/mp4" />
					</video>
					</div>

					<ATMarkdownView>## Demo: Optional Update Popup</ATMarkdownView>

					<div className="search-knowledgebase-screenshot-container">
					<video controls className="search-knowledgebase-screenshot">
						<source src={latest_update_popup_demo} type="video/mp4" />
					</video>
					</div>


					<ATMarkdownView>## End to End Setup</ATMarkdownView>
					<ATMarkdownView>To integrate Force Update feature in your application, you will need to setup below systems.</ATMarkdownView>
					<ATMarkdownView>1. Setup Sahayak portal [Read More]("#setup_sahayak_portal")</ATMarkdownView>
					<ATMarkdownView>2. Setup iOS app [Read More]("#setup_ios_app")</ATMarkdownView>
					<ATMarkdownView>3. Setup Android app [Read More]("#setup_android_app")</ATMarkdownView>


					<AppSpacer height="10px" />


					{/* BEGIN - SAHAYAK PORTAL SETUP */}
					<ATMarkdownView>### [Setup: Sahayak Portal](#setup_sahayak_portal)</ATMarkdownView>

					<ATMarkdownView>**Step 1.** Register and login to the Sahayak system.</ATMarkdownView>

					<ATMarkdownView>**Step 2.** Create a new Product in Sahayak portal.</ATMarkdownView>
					<ATMarkdownView>1. In the sahayak portal, click **Products** → **New Product**.</ATMarkdownView>
					<ATMarkdownView>2. Type the name of the product as you desire and submit the form.</ATMarkdownView>
					<ATMarkdownView>3. You can see the new product created in the product list page.</ATMarkdownView>
					<div className="search-knowledgebase-screenshot-container">
						<img
							src={new_product_01}
							className="search-knowledgebase-screenshot"
							onClick={this.didSelectQuickLookScreenshot.bind(this, new_product_01)}
						/>
					</div>

					<ATMarkdownView>**Step 3.** Create new API Key for the product.</ATMarkdownView>
					<ATMarkdownView>1. In the sahayak portal, go to **Product List** and click on the product which you want to configure. You will be taken to the product details page.</ATMarkdownView>
					<ATMarkdownView>2. In the product details page, scroll down to the **API Key** section. Click on **Generate API Key** button to create a new key.</ATMarkdownView>
					<ATMarkdownView>3. You will be asked to download and save the newly generated API key (or it will be saved in your downloads folder as per your browser settings). You can copy the API key from the downloaded file and use it in your mobile app codebase to integrate iOS and Android SahayakClient libraries.</ATMarkdownView>
					<div className="search-knowledgebase-screenshot-container">
						<img
							src={new_api_key_01}
							className="search-knowledgebase-screenshot"
							onClick={this.didSelectQuickLookScreenshot.bind(this, new_api_key_01)}
						/>
						<img
							src={new_api_key_02}
							className="search-knowledgebase-screenshot"
							onClick={this.didSelectQuickLookScreenshot.bind(this, new_api_key_02)}
						/>
					</div>

					<ATMarkdownView>**Step 4.** Set app-update related details as needed.</ATMarkdownView>
					<ATMarkdownView>1. In the product details page, scroll down to the **iOS App Details** and/or **Android App Details** section.</ATMarkdownView>
					<ATMarkdownView>2. Set **Bundle Identifier**, **Minimum Supported App Version**, **Latest App Version** and **App Download URL** as needed.</ATMarkdownView>

					<ATMarkdownView>> Note that **Minimum Supported App Version** is the version below which your app does not work and **Latest App Version** is the version which has all the latest functionalities. User can skip the latest-app-version but user can NOT skip minimum-supported-app-version. E.g. If the minimum-supported-app-version is 2.0.0 and latest-app-version is 2.2.0, then it means that your app version 1.9.0 does not work, 2.0.0 and 2.1.0 work but does not have latest functionalities and 2.2.0 works and it has latest functionalities.</ATMarkdownView>

					<ATMarkdownView>> Note that **App Download URL** is the URL of the page from where user can download your app. In most of the cases it will be the Apple App Store and Android Play Store URL.</ATMarkdownView>

					<div className="search-knowledgebase-screenshot-container">
						<img
							src={app_details_01}
							className="search-knowledgebase-screenshot"
							onClick={this.didSelectQuickLookScreenshot.bind(this, app_details_01)}
						/>
					</div>


					<AppSpacer height="10px" />


					{/* BEGIN - IOS APP SETUP */}
					<ATMarkdownView>### [Setup: iOS App](#setup_ios_app)</ATMarkdownView>

					<ATMarkdownView>**Step 1.** Install and import SahayakClient library in your app codebase.</ATMarkdownView>
					<ATMarkdownView>1. In the Xcode menu-bar click **File** → **Add Packages** or in the project-navigator go to **Project** → **Package Dependencies** → **Click + button**</ATMarkdownView>
					<div className="search-knowledgebase-screenshot-container">
						<img
							src={install_sahayakclient_spm_01}
							className="search-knowledgebase-screenshot"
							onClick={this.didSelectQuickLookScreenshot.bind(this, install_sahayakclient_spm_01)}
						/>
					</div>
					<ATMarkdownView>2. Paste the link ~https://github.com/aurvan/com-aurvan-sahayak-client-ios-release.git~ in the search field and press enter to search. You will see the available packages. Select SahayakClient and click Add Package.</ATMarkdownView>
					<div className="search-knowledgebase-screenshot-container">
						<img
							src={install_sahayakclient_spm_02}
							className="search-knowledgebase-screenshot"
							onClick={this.didSelectQuickLookScreenshot.bind(this, install_sahayakclient_spm_02)}
						/>
					</div>
					<ATMarkdownView>3. You will see SahayakClient library added in Package Dependencies for your project.</ATMarkdownView>
					<div className="search-knowledgebase-screenshot-container">
						<img
							src={install_sahayakclient_spm_03}
							className="search-knowledgebase-screenshot"
							onClick={this.didSelectQuickLookScreenshot.bind(this, install_sahayakclient_spm_03)}
						/>
					</div>

					<ATMarkdownView>**Step 2.** Configure SahayakClient library with the API Key</ATMarkdownView>
					<ATMarkdownView>{`~~~
						// AppDelegate.swift

						import SahayakClient

						class AppDelegate: UIResponder, UIApplicationDelegate {

							func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {
								self.setupSahayakClient()
								return true
							}

							private func setupSahayakClient() {
								SahayakClientUtility.shared.setup(apiKey: <#API_KEY_HERE#>, completion: {pResult in
									switch pResult {
									case .success:
										Swift.print("SahayakClient set-up successfully.")
									case .failure(let pError):
										Swift.print("SahayakClient set-up failed. Error:", pError.localizedDescription)
									}
								})
							}

						}
					~~~`}</ATMarkdownView>

					<ATMarkdownView>**Step 3.** Compile and run the app. You should be able to see the update-popup automatically if your app version is less than the version you set in Sahayak portal.</ATMarkdownView>

					<AppSpacer height="10px" />

					{/* BEGIN - ANDROID APP SETUP */}
					<ATMarkdownView>### [Setup: Android App](#setup_android_app)</ATMarkdownView>

					<ATMarkdownView>**Step 1.** Install and import SahayakClient library in your app codebase.</ATMarkdownView>
					<ATMarkdownView>1. Add (if not available by default) Maven Central repository in the dependency resolution settings.</ATMarkdownView>
					<ATMarkdownView>{`~~~
						// Filename: settings.gradle.kts

						dependencyResolutionManagement {
							repositories {
								mavenCentral()
							}
						}
					~~~`}</ATMarkdownView>

					<ATMarkdownView>2. Add SahayakClient library as dependency in the build gradle file.</ATMarkdownView>
					<ATMarkdownView>{`~~~
						// Filename: build.gradle.kts

						dependencies {
							implementation("com.aurvan:sahayak-client-android:1.0.0")
						}
					~~~`}</ATMarkdownView>

					<ATMarkdownView>**Step 2.** Configure SahayakClient library with the API Key</ATMarkdownView>
					<ATMarkdownView>{`~~~
						// Filename: MainActivity.kt

						class MainActivity : ComponentActivity() {

							override fun onCreate(savedInstanceState: Bundle?) {
								super.onCreate(savedInstanceState)
								this.setupSahayakClient()
							}

							private fun setupSahayakClient() {
								SahayakClientUtility.shared.setup(
									context = this.applicationContext
									, apiKey = <#API_KEY_HERE#>
									, completion = {pResult ->
										pResult.onSuccess {
											Log.d("app", "SahayakClient set-up successfully.")
										}
										pResult.onFailure {pError ->
											Log.d("app", "SahayakClient set-up failed. Error: \${pError.localizedMessage ?: pError.cause?.localizedMessage}")
										}
									}
								)
							}

						}
					~~~`}</ATMarkdownView>

					<ATMarkdownView>**Step 3.** Compile and run the app. You should be able to see the update-popup automatically if your app version is less than the version you set in Sahayak portal.</ATMarkdownView>


					<AppSpacer height="30px" />

				</div>

			</div>
		);
	}

	didSelectQuickLookScreenshot(pUrl) {
		var aUrl = pUrl;
		if (aUrl != null && FoundationComponent.sharedQuickLookComponent != null && FoundationComponent.sharedQuickLookComponent.current != null) {
			FoundationComponent.sharedQuickLookComponent.current.showWithUrl(aUrl);
		}
	}

}


export default SearchKnowledgebaseComponent;
